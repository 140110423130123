import EmbedCompetitionSeasonPhaseTeamSelector from 'components/Embeds/EmbedCompetitionSeasonPhaseTeamSelector';
import EmbedStatsWrapper from 'components/Embeds/EmbedStatsWrapper';
import EmbedTitle from 'components/Embeds/EmbedTitle';
import StandingsHeader from 'components/Embeds/StandingsHeader';
import StandingsRow from 'components/Embeds/StandingsRow';
import StandingsTable from 'components/Embeds/StandingsTable';
import StandingsTeamCell from 'components/Embeds/StandingsTeamCell';
import StandingsTextCell from 'components/Embeds/StandingsTextCell';
import useFetchData from 'hooks/useFetchData';
import useLang from 'hooks/useLang';
import { useState } from 'react';
import { ClockLoader } from 'react-spinners';

const EmbedStandingsInner = ({ competitionsData, lang, competitionId, seasonId: paramSeasonId, groupId: paramGroupId, phaseId: paramPhaseId }) => {
  const LANG = useLang(lang);
  const [seasonId, setSeasonId] = useState(paramSeasonId);
  const [groupId, setGroupId] = useState(paramGroupId);
  const [phaseId, setPhaseId] = useState(paramPhaseId);
  const { data, isLoading } = useFetchData(
    phaseId ? `/public/standings?phaseId=${phaseId}`
    : groupId ? `/public/standings?groupId=${groupId}`
    : seasonId ? `/public/standings?seasonId=${seasonId}`
    : null
  );


  return (
    <>
      <EmbedStatsWrapper>
        <EmbedTitle>{LANG.STANDINGS}</EmbedTitle>

        <EmbedCompetitionSeasonPhaseTeamSelector
          competitionsData={competitionsData}
          lang={lang}
          competitionId={competitionId}
          seasonId={paramSeasonId}
          groupId={paramGroupId}
          phaseId={paramPhaseId}
          setSeasonId={setSeasonId}
          setGroupId={setGroupId}
          setPhaseId={setPhaseId}
        />

        {!data && isLoading &&
          <div className="w-full h-[450px] flex justify-center items-center">
            <ClockLoader color="#aaa" size={40} />
          </div>
        }

        {data &&
          <>
            <StandingsTable>
                <StandingsRow>
                  <StandingsHeader className="w-[5%] text-left">{LANG.STANDINGS_COLUMN_SHORTS['Rank']}</StandingsHeader>
                  <StandingsHeader className="w-[37%] text-left !pl-8">{LANG.TEAM}</StandingsHeader>
                  <StandingsHeader className="w-[8%] text-right" tooltip={LANG.STANDINGS_COLUMN_TOOLTIPS['Total Games']}>{LANG.STANDINGS_COLUMN_SHORTS['Total Games']}</StandingsHeader>
                  <StandingsHeader className="w-[8%] text-right" tooltip={LANG.STANDINGS_COLUMN_TOOLTIPS['Wins']}>{LANG.STANDINGS_COLUMN_SHORTS['Wins']}</StandingsHeader>
                  <StandingsHeader className="w-[8%] text-right" tooltip={LANG.STANDINGS_COLUMN_TOOLTIPS['Draws']}>{LANG.STANDINGS_COLUMN_SHORTS['Draws']}</StandingsHeader>
                  <StandingsHeader className="w-[8%] text-right" tooltip={LANG.STANDINGS_COLUMN_TOOLTIPS['Losses']}>{LANG.STANDINGS_COLUMN_SHORTS['Losses']}</StandingsHeader>
                  <StandingsHeader className="w-[8%] text-center" tooltip={LANG.STANDINGS_COLUMN_TOOLTIPS['Score']}>{LANG.STANDINGS_COLUMN_SHORTS['Score']}</StandingsHeader>
                  <StandingsHeader className="w-[8%] text-right" tooltip={LANG.STANDINGS_COLUMN_TOOLTIPS['Total Points']} sorted={true}>{LANG.STANDINGS_COLUMN_SHORTS['Total Points']}</StandingsHeader>
                  <StandingsHeader className="w-[10%] text-right" tooltip={LANG.STANDINGS_COLUMN_TOOLTIPS['Total Penalty Minutes']}>{LANG.STANDINGS_COLUMN_SHORTS['Total Penalty Minutes']}</StandingsHeader>
                </StandingsRow>
                {data?.map((team, idx) => (
                  <StandingsRow key={idx}>
                    <StandingsTextCell className={`${idx == 0 ? 'pt-4' : ''}`} text={(idx+1) + '.'} align="left" />
                    <StandingsTeamCell className={`${idx == 0 ? 'pt-4' : ''}`} team={{ name: team['Team Name'], logoUrl: team.TeamLogo }} />
                    <StandingsTextCell className={`${idx == 0 ? 'pt-4' : ''}`} text={team['Total Games']} />
                    <StandingsTextCell className={`${idx == 0 ? 'pt-4' : ''}`} text={team['Wins']} />
                    <StandingsTextCell className={`${idx == 0 ? 'pt-4' : ''}`} text={team['Draws']} />
                    <StandingsTextCell className={`${idx == 0 ? 'pt-4' : ''}`} text={team['Losses']} />
                    <StandingsTextCell className={`${idx == 0 ? 'pt-4' : ''}`} text={
                      <span className="w-full flex gap-[1px]">
                        <span className="flex-1 text-right">{team['Score']?.split(':')?.[0]}</span>
                        :
                        <span className="flex-1 text-left">{team['Score']?.split(':')?.[1]}</span>
                      </span>
                    } align="center" />
                    <StandingsTextCell className={`${idx == 0 ? 'pt-4' : ''}`} text={team['Total Points']} sorted={true} />
                    <StandingsTextCell className={`${idx == 0 ? 'pt-4' : ''}`} text={team['Total Penalty Minutes']} />
                  </StandingsRow>
                ))}
            </StandingsTable>
          </>
        }
      </EmbedStatsWrapper>
    </>
  );
}

export default EmbedStandingsInner