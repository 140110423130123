import { Tooltip } from 'antd';
import Confirm from 'components/Confirm';
import HmsTableWithFilters from 'components/HmsTableWithFilters';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useIsMobile from 'hooks/useIsMobile';
import { useState } from 'react';
import useLoginStore from 'store/loginStore';
import { autoAddIdColumnForExport, autoAddLinkColumnForExport, autoAddLogoColumnForExport } from 'utils/autoColumns';
import icons from 'utils/icons';
import { removeAttributes } from 'utils/oneLiners';
import { simulateCtrlClick } from 'utils/simulateCtrlClick';
dayjs.extend(utc);

const RelationsTable = ({ data, sourceEntity, targetEntity, columns: columnsParam, emptyMessage, removeClicked: removeConfirmed, rowClickable, onRowClicked, addButton, buttons, extraButtonBarItems, cannotRemoveTooltip, rowSelection, canOpen }, ref) => {
    const [confirmDeleteId, setConfirmDeleteId] = useState(null);
    const isMobile = useIsMobile();
    const userRoles = useLoginStore(state => state.roles);
    const exportEnabled = userRoles?.includes('ADMIN')


    const columns = (
        (columnsParam ?? targetEntity.defaultColumnsForRelations)?.map(c => {
            if (typeof c == 'string') {
                return targetEntity.columns[c];
            }
            if (typeof c == 'object') {
                if (c.name) {
                    return {
                        ...targetEntity.columns[c.name],
                        ...removeAttributes(c, ['name']),
                    }
                }
                if (c.column) {
                    return {
                        ...targetEntity.columns[c.column],
                        ...removeAttributes(c, ['column']),
                    }
                }
                return c;
            }
            throw 'Invalid type for defaultColumnsForRelations. It should be an array of strings or objects';
        })
        ?? [{ ...(targetEntity.columns[targetEntity.defaultColumnName]) }])
        .filter(c => c);

    autoAddIdColumnForExport(targetEntity, columns);
    autoAddLinkColumnForExport(targetEntity, columns);
    autoAddLogoColumnForExport(targetEntity, columns);

    let hasDefaultSort = false;
    columns.some(c => {
        if (c.defaultSortOrder) {
            hasDefaultSort = true;
            return true;
        }
    });
    if (!hasDefaultSort) {
        if (columns[0]) {
            columns[0].defaultSortOrder = 'ascend';
        }
    }
    if (columns.length > 0) {
        if (!isMobile) {
            columns[0].fixed = 'left';
        }
    }

    // we need at least one column without width defined so that one column can grow (otherwise the Remove column is very ugly)
    let hasUndefinedWidth = false;
    for (const column of columns) {
        if (column.width === undefined) {
            hasUndefinedWidth = true;
            break;
        }
    }
    if (!hasUndefinedWidth) {
        delete columns[columns.length - 1].width;
    }

    const removeClicked = (id) => {
        setConfirmDeleteId(id);
    }

    const confirmDeleteOk = async () => {
        await removeConfirmed(confirmDeleteId);
        setConfirmDeleteId(null);
    }

    const confirmDeleteCancel = () => {
        setConfirmDeleteId(null);
    }

    const openClicked = (id, record) => {
        simulateCtrlClick(targetEntity.entityUrl(record));
    }

    return (
        <>
            <Confirm
                message={"Are you sure do you want to remove this relation?"}
                confirmButtonText="Delete"
                confirmButtonType="danger"
                visible={confirmDeleteId}
                handleConfirm={confirmDeleteOk}
                handleCancel={confirmDeleteCancel}
            />

            <HmsTableWithFilters
                filterStore="state"
                exportEnabled={exportEnabled}
                exportPrefix={sourceEntity.displayName + '-' + targetEntity.displayNamePlural}
                layoutType='plain'
                extraButtonBarItems={extraButtonBarItems}
                buttons={
                    [
                        ...(addButton ?
                            [
                                {
                                    label: addButton.label ?? 'Add ' + targetEntity.displayNamePlural,
                                    icon: addButton.icon ?? icons.Link,
                                    onClick: addButton.onClick,
                                }
                            ]
                            :
                            []),
                        ...(buttons ?? [])
                    ]
                }
                onRowClicked={onRowClicked}
                emptyMessage={emptyMessage}
                columns={[
                    ...columns,
                    ...(removeConfirmed || cannotRemoveTooltip || canOpen ? [
                        {
                            title: '',
                            sortDirections: [],
                            width: (removeConfirmed || cannotRemoveTooltip) && canOpen ? 100 : 50,
                            className: removeConfirmed && 'pointer-events-none',
                            fixed: !isMobile ? 'right' : undefined,
                            exportable: false,
                            filterDisabled: true,
                            render: (record) =>
                                <div className="flex justify-center gap-4">
                                    {canOpen &&
                                        <span>
                                            <span className="flex flex-nowrap gap-4 pointer-events-none">
                                                <a className="flex items-center gap-1 pointer-events-auto"
                                                    onClick={(e) => { e.stopPropagation(); openClicked(record[targetEntity.primaryKey], record); }}>
                                                    <span className="flex items-center justify-center gap-1">
                                                        {icons.ExternalLink}
                                                        Open
                                                    </span>
                                                </a>
                                            </span>
                                        </span>
                                    }
                                    {(removeConfirmed || cannotRemoveTooltip) &&
                                        <span>
                                            {
                                                removeConfirmed
                                                ?
                                                    <span className="flex flex-nowrap gap-4 pointer-events-none">
                                                        <a className="flex items-center gap-1 pointer-events-auto" onClick={(e) => { e.stopPropagation(); removeClicked(record[targetEntity.primaryKey]); }}>
                                                            <span className="flex items-center gap-1">
                                                                {icons.Trash}
                                                                Remove
                                                            </span>
                                                        </a>
                                                    </span>
                                                :
                                                    <Tooltip title={cannotRemoveTooltip}>
                                                        <span className="flex flex-nowrap gap-4 cursor-pointer text-gray-400">
                                                            <span className="flex items-center gap-1">
                                                                {icons.Trash}
                                                                Remove
                                                            </span>
                                                        </span>
                                                    </Tooltip>
                                            }
                                        </span>
                                    }
                                </div>
                        }
                    ] : []),
                ]}
                data={data}
                rowSelection={rowSelection}
                rowKey={targetEntity.primaryKey}
            />
        </>

    );
}

export default RelationsTable
