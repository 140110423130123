import useFetchEntity from 'hooks/useFetchEntity';
import { useEffect, useMemo, useRef } from 'react';

import "@fontsource/audiowide";
import "@fontsource/bebas-neue";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import DebugPrinter from 'components/Debug/DebugPrinter';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import useFetchEntities from 'hooks/useFetchEntities';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import usePersistentStore from 'store/persistentStore';
import BackgroundImgSelector from './Filters/BackgroundImgSelector';
import MarginEditor from './Filters/MarginEditor';
import PhaseSelector from './Filters/PhaseSelector';
import XyEditor from './Filters/XyEditor';
import ZoomEditor from './Filters/ZoomEditor';
import { dateFormatIG, enableZoomByMouseWheel, keyForZoomByMouseWheel, stepForMoveByMouse, stepForZoomByMouseWheel } from './IGconfig';
import IGHeader from './Parts/IGHeader';
import TableFaceoffs from './Parts/TableFaceoffs';

const IG9_TeamFaceOffEfficiency = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const bgPictureUrl = usePersistentStore((state) => state.get('ig-tfoe-bg-url'));
  const setBgPictureUrl = usePersistentStore((state) => state.set('ig-tfoe-bg-url'));
  const size = usePersistentStore((state) => state.get('ig-tfoe-size'));
  const setSize = usePersistentStore((state) => state.set('ig-tfoe-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 715, height: 1111, preset: 'PORTRAIT' };

  const bgImgPositionX = usePersistentStore((state) => state.get('ig-tfoe-bgimgposition-x')) ?? 50;
  const setBgImgPositionX = usePersistentStore((state) => state.set('ig-tfoe-bgimgposition-x'));
  const bgImgPositionY = usePersistentStore((state) => state.get('ig-tfoe-bgimgposition-y')) ?? 50;
  const setBgImgPositionY = usePersistentStore((state) => state.set('ig-tfoe-bgimgposition-y'));
  const bgImgZoom = usePersistentStore((state) => state.get('ig-tfoe-zoom')) ?? 100;
  const setBgImgZoom = usePersistentStore((state) => state.set('ig-tfoe-zoom'));
  const bgImgRef = useRef(null);

  const igname = 'standings';
  const marginDefaults = { top: 32, left: 32, bottom: 32, right: 32};
  const bgImgMargin = usePersistentStore((state) => state.get(`ig-${igname}-margin`)) ?? marginDefaults;
  const setBgImgMargin = usePersistentStore((state) => state.set(`ig-${igname}-margin`));

  useEffect(() => {
    if (!enableZoomByMouseWheel) return;

    const handleWheel = (e) => {
      if (!e[keyForZoomByMouseWheel]) return;
      e.preventDefault();
      const newZoom = (bgImgZoom == null || isNaN(bgImgZoom) ? 0 : bgImgZoom) + (e.deltaY > 0 ? -stepForZoomByMouseWheel : stepForZoomByMouseWheel); // Adjust zoom level
      setBgImgZoom(Math.max(50, Math.min(200, newZoom))); // Clamp zoom between 50% and 200%
    };

    const bgImgElement = bgImgRef.current;
    if (bgImgElement) {
      bgImgElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (bgImgElement) {
        bgImgElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [bgImgZoom, setBgImgZoom]);

  const handleBgImgMouseMove = (e) => {
    if (e.buttons !== 1) return; // Only move on left mouse click

    setBgImgPositionX(Math.min(100, Math.max(0, bgImgPositionX + e.movementX / stepForMoveByMouse)));
    setBgImgPositionY(Math.min(100, Math.max(0, bgImgPositionY + e.movementY / stepForMoveByMouse)));
  };

  const { phaseIdsAll } = usePhaseIdTranslator(membership);
  const groupId = membership?.groupId;
  const seasonId = membership?.seasonId;

  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');
  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');


  const metadata = useMemo(() => {
    return {
      GroupName: groupDetail?.name,
      GroupColor: groupDetail?.color,
      SeasonName: seasonDetail?.name,
      CompetitionName: seasonDetail?.Competition?.name,
      CompetitionLogo:
        seasonDetail?.Competition?.logoUrl?.replace(
          "[size]",
          "cropped_md",
        ),
      Date: dayjs().format(dateFormatIG),
    };
  }, [groupDetail, seasonDetail]);

  const { data: allGamesStatistics } = useFetchEntities(
    phaseIdsAll != null && 'Game',
    {
      filters: { phaseId: phaseIdsAll },
      extraAttributes: ["HomeTeamGoals", "AwayTeamGoals", "HomeTeamFaceOffs", "AwayTeamFaceOffs"],
      relations: 'Venue(name,logoUrl),HomeTeam,AwayTeam',
    }
  );

  const TeamStats = useMemo(() => {
    const teamStats = allGamesStatistics
      ?.filter((game) => game.HomeTeam && game.AwayTeam)
      .reduce((acc, game) => {
        if (!acc[game.HomeTeam.name]) {
          acc[game.HomeTeam.name] = {
            Name: game.HomeTeam.name,
            Logo: game.HomeTeam.logoUrl?.replace("[size]", "cropped_md"),
            GP: 0,
            W: 0,
            WonFaceOffs: 0,
            LostFaceOffs: 0,
          };
        }
        if (!acc[game.AwayTeam.name]) {
          acc[game.AwayTeam.name] = {
            Name: game.AwayTeam.name,
            Logo: game.AwayTeam.logoUrl?.replace("[size]", "cropped_md"),
            GP: 0,
            W: 0,
            WonFaceOffs: 0,
            LostFaceOffs: 0,
          };
        }
        acc[game.HomeTeam.name].GP += 1;
        acc[game.HomeTeam.name].W +=
          game.HomeTeamGoals > game.AwayTeamGoals ? 1 : 0;
        acc[game.HomeTeam.name].WonFaceOffs += game.HomeTeamFaceOffs;
        acc[game.HomeTeam.name].LostFaceOffs += game.AwayTeamFaceOffs;
        acc[game.AwayTeam.name].GP += 1;
        acc[game.AwayTeam.name].W +=
          game.AwayTeamGoals > game.HomeTeamGoals ? 1 : 0;
        acc[game.AwayTeam.name].WonFaceOffs += game.AwayTeamFaceOffs;
        acc[game.AwayTeam.name].LostFaceOffs += game.HomeTeamFaceOffs;
        return acc;
      }, {});

    return teamStats;
  }, [allGamesStatistics]);

  const data = useMemo(() => {
    const data = Object.values(TeamStats ?? {}).map((team) => ({
      ...team,
      FaceOffEfficiency: (
        team.WonFaceOffs / (team.WonFaceOffs + team.LostFaceOffs || 1)
      ).toFixed(2),
    }));

    const sorted = data.sort((a, b) => {
      return b.FaceOffEfficiency - a.FaceOffEfficiency;
    });

    return sorted.slice(0, 15);
  }, [TeamStats]);


  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Background picture:</span>
        <div>
          <BackgroundImgSelector bgPictureUrl={bgPictureUrl} setBgPictureUrl={setBgPictureUrl} igId={9} />
          <div className='flex gap-6 items-center mt-2'>
            <XyEditor
              xValue={bgImgPositionX}
              yValue={bgImgPositionY}
              setXValue={setBgImgPositionX}
              setYValue={setBgImgPositionY}
              suffixX='X'
              suffixY='Y'
              STEP={10}
              isPercentage={true}
            />
            <ZoomEditor zoomLevel={bgImgZoom} setZoomLevel={setBgImgZoom} />
          </div>
        </div>

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>



        <span>Margin:</span>
        <div className="flex gap-2 items-center">
          <MarginEditor
            margin={bgImgMargin}
            setMargin={setBgImgMargin}
            defaults={marginDefaults}
          />
        </div>
        <div />
      </div>

      {showData && (<DebugPrinter data={[metadata, TeamStats, data, allGamesStatistics]}></DebugPrinter>)}

      {phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto w-[715px] bg-hockey-page-bg font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <div style={{
            paddingTop: `${bgImgMargin.top}px`,
            paddingLeft: `${bgImgMargin.left}px`,
            paddingRight: `${bgImgMargin.right}px`,
            paddingBottom: `${bgImgMargin.bottom}px`,
          }}>
            <IGHeader
              title="Faceoffs efficiency"
              league={metadata.GroupName}
              leagueStyle={{ color: metadata.GroupColor ?? '#777777' }}
              small
              date={metadata.Date}
            />
            <TableFaceoffs data={data} />
          </div>

          {bgPictureUrl && <div
            ref={bgImgRef}
            onMouseMove={handleBgImgMouseMove}
            className='w-full relative my-24 aspect-[12/5]'
            style={{
              backgroundImage: `url('${process.env.REACT_APP_IMAGE_PROXY_URL}?url=${bgPictureUrl}')`,
              backgroundSize: `${bgImgZoom ?? 100}%`,
              backgroundPosition: `${bgImgPositionX ?? 50}% ${bgImgPositionY ?? 50}%`, // Dynamic positioning
              backgroundRepeat: 'no-repeat',
              cursor: 'grab', // Visual cue for draggable behavior
              clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
            }}
            onMouseDown={(e) => e.target.style.cursor = 'grabbing'} // Change cursor while dragging
            onMouseUp={(e) => e.target.style.cursor = 'grab'} // Reset cursor
          ></div>}
          <p className="text-center text-gray-400">
            Generated with: HMS | more info at: phmcup.cz/hms
          </p>
        </article>
      }
    </>
  );
};

export default IG9_TeamFaceOffEfficiency;
