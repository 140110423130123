import { Button, InputNumber } from 'antd';

const MarginEditor = ({ margin, setMargin, step = 10, defaults = { top: 0, bottom: 0, right: 0, left: 0 } }) => {

  const handleIncrease = (side) => {
    setMargin(prev => { prev = prev ?? defaults; return { ...prev, [side]: prev[side] + step } });
  };

  const handleDecrease = (side) => {
    setMargin(prev => { prev = prev ?? defaults; return { ...prev, [side]: prev[side] - step } });
  };

  const handleReset = () => {
    setMargin(defaults);
  };

  return (
    <div className="flex space-x-4 items-center">
      <div className="flex items-center space-x-2">
        <Button onClick={() => handleDecrease('right')}>-</Button>
        <InputNumber value={margin.right} onChange={(value) => setMargin(prev => ({ ...prev, right: value }))} step={step} suffix="R" style={{ width: '74px' }} />
        <Button onClick={() => handleIncrease('right')}>+</Button>
      </div>
      <div className="flex items-center space-x-2">
        <Button onClick={() => handleDecrease('left')}>-</Button>
        <InputNumber value={margin.left} onChange={(value) => setMargin(prev => ({ ...prev, left: value }))} step={step} suffix="L" style={{ width: '74px' }} />
        <Button onClick={() => handleIncrease('left')}>+</Button>
      </div>
      <div className="flex items-center space-x-2">
        <Button onClick={() => handleDecrease('top')}>-</Button>
        <InputNumber value={margin.top} onChange={(value) => setMargin(prev => ({ ...prev, top: value }))} step={step} suffix="T" style={{ width: '74px' }} />
        <Button onClick={() => handleIncrease('top')}>+</Button>
      </div>
      <div className="flex items-center space-x-2">
        <Button onClick={() => handleDecrease('bottom')}>-</Button>
        <InputNumber value={margin.bottom} onChange={(value) => setMargin(prev => ({ ...prev, bottom: value }))} step={step} suffix="B" style={{ width: '74px' }} />
        <Button onClick={() => handleIncrease('bottom')}>+</Button>
      </div>

      <Button onClick={handleReset}>Reset</Button>
    </div>
  );
};

export default MarginEditor;