import { Menu } from 'antd';
import useLists from 'hooks/useLists';
import useOrganization from 'hooks/useOrganization';
import useVersion from 'hooks/useVersion';
import { useLocation } from 'react-router-dom';
import useLoginStore from 'store/loginStore';
import icons from 'utils/icons';
import OrgLink from './System/OrgLink';

const Navbar = ({ mode, setMenuIsOpen }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { schema } = useLists();
  const { version } = useVersion();
  const roles = useLoginStore(s => s.roles);
  const { organization } = useOrganization();

  const showInfographics = organization?.showInfographics;
  const showScheduler = organization?.showScheduler;

  const selectedKeys = [
    currentPath
      .replace('settings/', 'settings__')
      .replace('rulepacks/', 'rulepacks__')
      .split('/')[3]
      .replace('settings__', 'settings/')
      .replace('rulepacks__', 'rulepacks/')
  ];

  const getNavbarItems = () => {
    if (roles.indexOf('ADMIN') != -1) {
      switch (mode) {
        case 'general':
          return [
              { key: "dashboard", icon: icons.Dashboard, label: <OrgLink to="/dashboard">Dashboard</OrgLink> },
              { key: "competitions", icon: icons.Competition, label: <OrgLink to="/competitions">Competitions</OrgLink> },
              { key: "sponsors", icon: icons.Sponsor, label: <OrgLink to="/sponsors">Sponsors</OrgLink> },
              { key: "venues", icon: icons.Venue, label: <OrgLink to="/venues">Venues</OrgLink> },
              { key: "awards", icon: icons.Award, label: <OrgLink to="/awards">Awards</OrgLink> },
              { key: "teams", icon: icons.Team, label: <OrgLink to="/teams">Teams</OrgLink> },
              { key: "players", icon: icons.Player, label: <OrgLink to="/players">Players</OrgLink> },
              { key: "persons", icon: icons.Person, label: <OrgLink to="/persons">Persons</OrgLink>, link: "/persons" },
              { key: "games", icon: icons.Game, label: <OrgLink to="/games">Games</OrgLink> },
              { key: "disciplinaries", icon: icons.Disciplinary, label: <OrgLink to="/disciplinaries">Disciplinaries</OrgLink> },
              { key: "seasonregistrations", icon: icons.SeasonRegistration, label: <OrgLink to="/seasonregistrations">Registrations</OrgLink> },
              showScheduler && { key: "schedulerconfigs", icon: icons.Scheduler, label: <OrgLink to="/schedulerconfigs">Scheduler</OrgLink> },
              showInfographics && { key: "infographics", icon: icons.Infographics, label: <OrgLink to="/infographics">Infographics</OrgLink> },
              { key: "statistics", icon: icons.Statistics, label: <OrgLink to="/statistics">Statistics</OrgLink> },
              { key: "rulepacks", icon: icons.Settings, label: <OrgLink to="/rulepacks">Rule Packs</OrgLink>, placement: 'bottom' },
            ].filter(i => i);
        case 'settings':
          return [
            {
              key: "settings",
              icon: icons.Settings,
              label: "Settings",
              children: [
                { key: "settings/organizations", icon: icons.Settings, label: <OrgLink to="/settings/organizations">Organizations</OrgLink> },
                { key: "settings/users", icon: icons.Settings, label: <OrgLink to="/settings/users">Users</OrgLink> },
              ],
            },
            { key: "general", icon: icons.Back, label: <OrgLink to="/competitions">Back to General</OrgLink> },
          ];
        case 'rulepacks':
          return [
            {
              key: "rulepacks",
              icon: icons.Settings,
              label: "Rule Packs",
              children:
                [
                  { key: 'rulepacks/ListEventLocations', icon: null, label: <OrgLink to={'/rulepacks/ListEventLocations'}>Event Locations</OrgLink> },
                  { key: 'rulepacks/ListGoalLocations', icon: null, label: <OrgLink to={'/rulepacks/ListGoalLocations'}>Goal Locations</OrgLink> },
                  { key: 'rulepacks/ListGoalTypes', icon: null, label: <OrgLink to={'/rulepacks/ListGoalTypes'}>Goal Types</OrgLink> },
                  { key: 'rulepacks/ListGoalSubtypes', icon: null, label: <OrgLink to={'/rulepacks/ListGoalSubtypes'}>Goal Subtypes</OrgLink> },
                  { key: 'rulepacks/ListInjuryTypes', icon: null, label: <OrgLink to={'/rulepacks/ListInjuryTypes'}>Injury Types</OrgLink> },
                  { key: 'rulepacks/ListPenaltyTypes', icon: null, label: <OrgLink to={'/rulepacks/ListPenaltyTypes'}>Penalty Types</OrgLink> },
                  { key: 'rulepacks/ListPenaltySubtypes', icon: null, label: <OrgLink to={'/rulepacks/ListPenaltySubtypes'}>Penalty Subtypes</OrgLink> },
                  { key: 'rulepacks/ListPeriods', icon: null, label: <OrgLink to={'/rulepacks/ListPeriods'}>Periods</OrgLink> },
                  { key: 'rulepacks/ListPlayerLevels', icon: null, label: <OrgLink to={'/rulepacks/ListPlayerLevels'}>Player Levels</OrgLink> },
                  { key: 'rulepacks/ListPersonRoles', icon: null, label: <OrgLink to={'/rulepacks/ListPersonRoles'}>Person Roles</OrgLink> },
                  { key: 'rulepacks/ListPositions', icon: null, label: <OrgLink to={'/rulepacks/ListPositions'}>Positions</OrgLink> },
                  { key: 'rulepacks/ListResultPoints', icon: null, label: <OrgLink to={'/rulepacks/ListResultPoints'}>Result Points</OrgLink> },
                  { key: 'rulepacks/ListResultTypes', icon: null, label: <OrgLink to={'/rulepacks/ListResultTypes'}>Result Types</OrgLink> },
                  { key: 'rulepacks/ListShotTypes', icon: null, label: <OrgLink to={'/rulepacks/ListShotTypes'}>Shot Types</OrgLink> },
                  { key: 'rulepacks/ListColors', icon: null, label: <OrgLink to={'/rulepacks/ListColors'}>Colors</OrgLink> },
                ]
            },
            { key: "general", icon: icons.Back, label: <OrgLink to="/competitions">Back to General</OrgLink> },
          ];
        default:
          return [];
      }
    }
    if (roles.indexOf('TEAM_MANAGER') != -1) {
      return [
        { key: "dashboard_team_manager", icon: icons.Dashboard, label: <OrgLink to="/dashboard_team_manager">Dashboard</OrgLink> },
        { key: "games_team_manager", icon: icons.Game, label: <OrgLink to="/games_team_manager">My Games</OrgLink> },
        { key: "teams_team_manager", icon: icons.Team, label: <OrgLink to="/teams_team_manager">My Teams</OrgLink> },
        { key: "players_team_manager", icon: icons.Player, label: <OrgLink to="/players_team_manager">My Players</OrgLink> },
        { key: "persons_team_manager", icon: icons.Person, label: <OrgLink to="/persons_team_manager">My Persons</OrgLink> },
        { key: "contacts_team_manager", icon: icons.Contacts, label: <OrgLink to="/contacts_team_manager">My Contacts</OrgLink> },
      ];
    }
  };

  const NavbarTop = () => (
    <Menu
      className="select-none !h-fit"
      onClick={() => setMenuIsOpen(false)}
      mode="inline"
      selectedKeys={selectedKeys}
      defaultOpenKeys={['general', 'settings', 'rulepacks']}
      style={{ height: '100%', borderRight: 0 }}
      items={getNavbarItems()?.filter(item => item.placement != 'bottom')}
    />
  );

  const NavbarBottom = () => (
    <Menu
      className="select-none"
      onClick={() => setMenuIsOpen(false)}
      mode="inline"
      selectedKeys={selectedKeys}
      defaultOpenKeys={['general', 'settings']}
      style={{ borderRight: 0 }}
      items={getNavbarItems()?.filter(item => item.placement == 'bottom')}
    />
  );

  const Schema = () => (
    <div className="pl-7 text-xs text-gray-400">Version: {version} {schema.replace('dbo_', '')}</div>
  );

  return (
    <>
      <NavbarTop />
        <div className="mt-auto mb-1">
          <NavbarBottom />
          <Schema />
      </div>
    </>
  )
}

export default Navbar