import { Input } from 'antd';
import { useParams } from 'react-router-dom';
import SimpleS3UploadFile from '../../SimpleS3UploadFile';

function BackgroundImgSelector({ bgPictureUrl, setBgPictureUrl, igId }) {
    const { orgId: orgSlug } = useParams();
    const setUrlToInput = (url) => { setBgPictureUrl(url) }

    console.log("BackgroundImgSelector", bgPictureUrl, setBgPictureUrl, igId, orgSlug)

    return (
        <div className='flex gap-2'>
            <Input className='col-span-4' value={bgPictureUrl} onChange={(e) => setBgPictureUrl(e.target.value)} />
            <SimpleS3UploadFile className='col-span-1' prefix={`infographics/${orgSlug}/ig${igId}`} onUploadSuccess={setUrlToInput} />
        </div>
    )
}

export default BackgroundImgSelector