import EntityLogo from "components/EntityLogo";
import HmsTable from "components/HmsTable";
import HmsTableLayout from "components/HmsTableLayout";
import useEntities from "hooks/useEntities";
import useFetchEntities from "hooks/useFetchEntities";
import usePageTitle from "hooks/usePageTitle";
import { entityColumnsToAntColumns } from "utils/entityColumnsToAntColumns";
import icons from "utils/icons";

const ContactsTeamManager = () => {
  const entities = useEntities();
  const createAntStrCol = (title, render, width = 200) => {
    return {
      sortDirections: [],
      title: title,
      width,
      render: render,
    };
  };

  const teamNameWithLogo = entityColumnsToAntColumns(entities.Team, ["NameWithLogo"])[0];
  teamNameWithLogo.title = "Team";

  const tableColumns = [
    teamNameWithLogo,
    createAntStrCol("Managers",
      (team) =>
        <span className="flex flex-col gap-3">
          {team.Managers?.map((manager, idx) => (
              <span key={idx}>{`${manager.firstName} ${manager.lastName}`}</span>
          ))}
        </span>
    ),
    createAntStrCol("Emails", 
      (team) =>
        <span className="flex flex-col gap-3">
          {team.Managers?.map((manager, idx) => (
              <span key={idx}>{`${manager.email ?? '-'}`}</span>
          ))}
        </span>
      , 300),
    createAntStrCol("Phones", 
      (team) =>
        <span className="flex flex-col gap-3">
          {team.Managers?.map((manager, idx) => (
              <span key={idx}>{`${manager.phone ?? '-'}`}</span>
          ))}
        </span>
    ),
  ];

  const {
    data: dataTeam,
    isError,
  } = useFetchEntities("Team", {
    relations: "Managers(firstName,lastName,email,phone)",
    onlyAttributes: ["name", "logoUrl"],
  });

  usePageTitle("Contacts");

  return (
    <>
      <HmsTableLayout
        breadcrumbs={[
          { title: "Home", icon: icons.Home, link: "/" },
          { title: "Contacts", icon: icons.Contacts },
        ]}
      >
        <HmsTable
          columns={tableColumns}
          data={dataTeam}
          title="Contacts"
          exportEnabled={false}
          isError={isError}
          renderMobileRow={(team) => (
            <div>
              <div className="border-b-[1px] border-gray-100 px-4 py-4 flex rounded-t-md" style={{ background: 'linear-gradient(90deg, #f2f2f2 0%, #fafafa 2%, #fafafa 98%, #f2f2f2 100%)' }}>
                <div className="flex flex-col gap-[1px]">
                  <span className="flex items-center gap-3">
                    <EntityLogo entity={team} size={24} />
                    <span className="font-bold text-[0.9rem]">{team?.name}</span>
                  </span>
                </div>
              </div>
              <div className="px-6 py-4 flex items-center rounded-b-md" style={{ background: 'linear-gradient(90deg, #f8f8f8 0%, #fff 2%, #fff 98%, #f8f8f8 100%)' }}>
                <div className="flex flex-col gap-5">
                  {team.Managers?.map((manager, idx) => (
                    <div key={idx} className="w-full grid grid-cols-4 gap-y-2">
                      <span className="text-gray-500">Manager:</span>
                      <span className="col-span-3">{`${manager.firstName} ${manager.lastName}`}</span>
                      <span className="text-gray-500">Email:</span>
                      <span className="col-span-3">{manager.email ?? '-'}</span>
                      <span className="text-gray-500">Phone:</span>
                      <span className="col-span-3">{manager.phone ?? '-'}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        />
        {/* <DebugPrinter data={[tableColumns, dataTeam]} /> */}
      </HmsTableLayout>
    </>
  );
};

export default ContactsTeamManager;
