import { Tag, Tooltip } from 'antd';
import EntityLogo from 'components/EntityLogo';
import HmsEntityTableController from 'components/HmsEntityTableController';
import HmsStatusTag from 'components/HmsStatusTag';
import useEntities from 'hooks/useEntities';
import { BsFillPersonCheckFill, BsFillPersonXFill } from "react-icons/bs";
import { getStatusText } from 'utils/status';

const TeamsTeamManager = () => {
  const entities = useEntities();
  const filterGroups = null;
  const showColumns = [
    'NameWithLogo',
    'Status',
    'ActivePlayerCount',
    'TotalPlayerCount',
    'TeamColor1',
    'TeamColor2',
    'TeamColor3',
    'JerseyColor1',
    'JerseyColor2',
    'JerseyColor3',
  ];

  return (
    <HmsEntityTableController
      entity={entities.Team}
      filterGroups={filterGroups}
      exportEnabled={false}
      showColumns={showColumns}
      onRowClickLink={(record) => `/teams_team_manager/${record.teamId}`}
      createUrl={'/teams_team_manager/_new_'}
      renderMobileRow={(record) => (
        <div>
          <div className="border-b-[1px] border-gray-100 px-4 py-4 flex rounded-t-md" style={{ background: 'linear-gradient(90deg, #f2f2f2 0%, #fafafa 2%, #fafafa 98%, #f2f2f2 100%)' }}>
            <div className="flex flex-col gap-[1px]">
              <span className="flex items-center gap-3">
                  <EntityLogo entity={record} size={24} />
                  <span className="font-bold text-[0.9rem]">{record?.name}</span>
              </span>
            </div>
            <div className="ml-auto my-auto flex items-center gap-1">
              <Tooltip title={`${record.ActivePlayerCount} Active Player${record.ActivePlayerCount != 1 ? 's' : ''}`}>
                <Tag color={
                    record.PlayerCount <= 0 ? 'red' :
                    record.PlayerCount < 12 ? 'orange' :
                    'success'}>
                  <span className="flex items-center gap-1">
                    <BsFillPersonCheckFill className="text-[0.9rem]" />
                    <span className="text-[0.9rem]">{record.ActivePlayerCount}</span>
                  </span>
                </Tag>
              </Tooltip>
              <Tooltip title={`${record.TotalPlayerCount} Total Player${record.TotalPlayerCount != 1 ? 's' : ''}`}>
                <Tag>
                  <span className="flex items-center gap-1">
                    <BsFillPersonXFill className="text-[0.9rem]" />
                    <span className="text-[0.9rem]">{record.TotalPlayerCount}</span>
                  </span>
                </Tag>
              </Tooltip>
              <Tooltip title={`Team Status: ${getStatusText(record.status, 'Team')}`}>
                <HmsStatusTag className="m-0" status={record.status} entity="Team" />
              </Tooltip>
            </div>
          </div>
          <div className="px-6 py-4 flex items-center rounded-b-md" style={{ background: 'linear-gradient(90deg, #f8f8f8 0%, #fff 2%, #fff 98%, #f8f8f8 100%)' }}>
            <div className="w-full grid grid-cols-4 gap-y-2">
              <span className="text-gray-500">Short:</span>
              <span className="col-span-3">{record?.short}</span>
              <span className="text-gray-500">Nick:</span>
              <span className="col-span-3">{record?.nick}</span>
            </div>
          </div>
        </div>
      )}
    />
  )
}

export default TeamsTeamManager