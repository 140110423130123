import AdminLayout from 'components/Layouts/AdminLayout';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider
} from "react-router-dom";

import AppErrorLayout from 'components/Layouts/AppErrorLayout';
import EmbedLayout from 'components/Layouts/EmbedLayout';
import LoginWrapper from 'components/Layouts/LoginWrapper';
import SchedulerLayout from 'components/Layouts/SchedulerLayout';
import NavigateHome from 'components/NavigateHome';
import OrgNavigate from 'components/System/OrgNavigate';
import Award from 'pages/Award';
import Awards from 'pages/Awards';
import Competition from 'pages/Competition';
import Competitions from 'pages/Competitions';
import ContactsTeamManager from 'pages/ContactsTeamManager';
import Dashboard from 'pages/Dashboard';
import Disciplinaries from 'pages/Disciplinaries';
import Disciplinary from 'pages/Disciplinary';
import EmbedDisciplinaries from 'pages/EmbedDisciplinaries';
import EmbedDisciplinary from 'pages/EmbedDisciplinary';
import EmbedGame from 'pages/EmbedGame';
import EmbedGames from 'pages/EmbedGames';
import EmbedGoaliesStats from 'pages/EmbedGoaliesStats';
import EmbedNewPlayer from 'pages/EmbedNewPlayer';
import EmbedPlayersStats from 'pages/EmbedPlayersStats';
import EmbedSeasonTerms from 'pages/EmbedSeasonTerms';
import EmbedStandings from 'pages/EmbedStandings';
import ForgotPassword from 'pages/ForgotPassword';
import Game from 'pages/Game';
import GamePlay from 'pages/GamePlay';
import Games from 'pages/Games';
import GamesTeamManager from 'pages/GamesTeamManager';
import GameTeamManager from 'pages/GameTeamManager';
import Group from 'pages/Group';
import Infographics from 'pages/Infographics';
import MyProfile from 'pages/MyProfile';
import Organization from 'pages/Organization';
import Organizations from 'pages/Organizations';
import PaymentStatus from 'pages/PaymentStatus';
import Person from 'pages/Person';
import Persons from 'pages/Persons';
import PersonsTeamManager from 'pages/PersonsTeamManager';
import Phase from 'pages/Phase';
import Player from 'pages/Player';
import Players from 'pages/Players';
import PlayersTeamManager from 'pages/PlayersTeamManager';
import ResetPassword from 'pages/ResetPassword';
import RulePackList from 'pages/RulePackList';
import RulePackRecord from 'pages/RulePackRecord';
import SchedulerConfig from 'pages/SchedulerConfig';
import SchedulerConfigs from 'pages/SchedulerConfigs';
import SchedulerSimulation from 'pages/SchedulerSimulation';
import Season from 'pages/Season';
import SeasonRegistration from 'pages/SeasonRegistration';
import SeasonRegistrations from 'pages/SeasonRegistrations';
import Sponsor from 'pages/Sponsor';
import Sponsors from 'pages/Sponsors';
import Statistics from 'pages/Statistics';
import Team from 'pages/Team';
import Teams from 'pages/Teams';
import TeamsTeamManager from 'pages/TeamsTeamManager';
import User from 'pages/User';
import Users from 'pages/Users';
import Venue from 'pages/Venue';
import Venues from 'pages/Venues';
import { PREFIX_PATH } from 'utils/prefixRelativeOrg';

export default function Router() {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/auth">
                    <Route path="forgotPassword" element={<ForgotPassword />} />
                    <Route path="resetPassword" element={<ResetPassword />} />
                </Route>

                <Route path={`/`} element={<LoginWrapper><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="" element={<NavigateHome homesByRole={{ 'ADMIN': '/dashboard', 'TEAM_MANAGER': '/dashboard_team_manager' }} replace={true} />} />
                </Route>
                <Route path={`/${PREFIX_PATH}/:orgId`} element={<LoginWrapper><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="" element={<NavigateHome homesByRole={{ 'ADMIN': '/dashboard', 'TEAM_MANAGER': '/dashboard_team_manager' }} replace={true} />} />
                </Route>
                <Route path={`/${PREFIX_PATH}/:orgId`} element={<LoginWrapper roles={['ADMIN', 'TEAM_MANAGER']}><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="profile" element={<MyProfile />} />
                </Route>
                <Route path={`/${PREFIX_PATH}/:orgId`} element={<LoginWrapper roles={['ADMIN']}><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="competitions" element={<Competitions />} />
                    <Route path="competitions" element={<Competitions />} />
                    <Route path="competitions/:id" element={<Competition />} />
                    <Route path="seasons/:id" element={<Season />} />
                    <Route path="groups/:id" element={<Group />} />
                    <Route path="phases/:id" element={<Phase />} />
                    <Route path="sponsors" element={<Sponsors />} />
                    <Route path="sponsors/:id" element={<Sponsor />} />
                    <Route path="venues" element={<Venues />} />
                    <Route path="venues/:id" element={<Venue />} />
                    <Route path="awards" element={<Awards />} />
                    <Route path="awards/:id" element={<Award />} />
                    <Route path="teams" element={<Teams />} />
                    <Route path="teams/:id" element={<Team />} />
                    <Route path="players" element={<Players />} />
                    <Route path="players/:id" element={<Player />} />
                    <Route path="persons" element={<Persons />} />
                    <Route path="persons/:id" element={<Person />} />
                    <Route path="games" element={<Games />} />
                    <Route path="games/:id" element={<Game />} />
                    <Route path="statistics" element={<Statistics />} />
                    <Route path="schedulerconfigs" element={<SchedulerConfigs />} />
                    <Route path="schedulerconfigs/:id" element={<SchedulerConfig />} />
                    <Route path="infographics" element={<Infographics />} />
                    <Route path="disciplinaries" element={<Disciplinaries />} />
                    <Route path="disciplinaries/:id" element={<Disciplinary />} />
                    <Route path="seasonregistrations" element={<SeasonRegistrations />} />
                    <Route path="seasonregistrations/:id" element={<SeasonRegistration />} />
                </Route>
                <Route path={`/${PREFIX_PATH}/:orgId`} element={<LoginWrapper><AdminLayout mode="general" navbar={false} /></LoginWrapper>}>
                    <Route path="gameplay/:id" element={<GamePlay />} />
                    <Route path="payment-status" element={<PaymentStatus />} />
                </Route>
                <Route path={`/${PREFIX_PATH}/:orgId`} element={<LoginWrapper><SchedulerLayout /></LoginWrapper>}>
                    <Route path="schedulerruns/:id" element={<SchedulerSimulation />} />       { /* legacy */}
                    <Route path="schedulersimulations/:id" element={<SchedulerSimulation />} />
                </Route>
                <Route path={`/${PREFIX_PATH}/:orgId`} element={<LoginWrapper roles={['TEAM_MANAGER']}><AdminLayout mode="general" /></LoginWrapper>}>
                    <Route path="dashboard_team_manager" element={<Dashboard />} />
                    <Route path="games_team_manager" element={<GamesTeamManager />} />
                    <Route path="games_team_manager/:id" element={<GameTeamManager />} />
                    <Route path="teams_team_manager" element={<TeamsTeamManager />} />
                    <Route path="teams_team_manager/:id" element={<Team />} />
                    <Route path="players_team_manager" element={<PlayersTeamManager />} />
                    <Route path="players_team_manager/:id" element={<Player />} />
                    <Route path="persons_team_manager" element={<PersonsTeamManager />} />
                    <Route path="persons_team_manager/:id" element={<Person />} />
                    <Route path="contacts_team_manager" element={<ContactsTeamManager />} />
                </Route>
                <Route path={`/${PREFIX_PATH}/:orgId/settings`} element={<LoginWrapper roles={['ADMIN']}><AdminLayout mode="settings" /></LoginWrapper>}>
                    <Route path="organizations" element={<Organizations />} />
                    <Route path="organizations/:id" element={<Organization />} />
                    <Route path="system_settings" element={<div>settings/system_settings</div>} />
                    <Route path="system_settings/:id" element={<></>} />
                    <Route path="users" element={<Users />} />
                    <Route path="users/:id" element={<User />} />
                    <Route path="" element={<OrgNavigate to="/settings/organizations" replace />} />
                </Route>
                <Route path={`/${PREFIX_PATH}/:orgId/rulepacks`} element={<LoginWrapper roles={['ADMIN']}><AdminLayout mode="rulepacks" /></LoginWrapper>}>
                    <Route path="ListEventLocations" element={<RulePackList entity="ListEventLocation" />} />
                    <Route path="ListEventLocations/:id" element={<RulePackRecord entity="ListEventLocation" />} />
                    <Route path="ListGoalLocations" element={<RulePackList entity="ListGoalLocation" />} />
                    <Route path="ListGoalLocations/:id" element={<RulePackRecord entity="ListGoalLocation" />} />
                    <Route path="ListGoalSubtypes" element={<RulePackList entity="ListGoalSubtype" />} />
                    <Route path="ListGoalSubtypes/:id" element={<RulePackRecord entity="ListGoalSubtype" />} />
                    <Route path="ListGoalTypes" element={<RulePackList entity="ListGoalType" />} />
                    <Route path="ListGoalTypes/:id" element={<RulePackRecord entity="ListGoalType" />} />
                    <Route path="ListInjuryTypes" element={<RulePackList entity="ListInjuryType" />} />
                    <Route path="ListInjuryTypes/:id" element={<RulePackRecord entity="ListInjuryType" />} />
                    <Route path="ListPenaltySubtypes" element={<RulePackList entity="ListPenaltySubtype" />} />
                    <Route path="ListPenaltySubtypes/:id" element={<RulePackRecord entity="ListPenaltySubtype" />} />
                    <Route path="ListPenaltyTypes" element={<RulePackList entity="ListPenaltyType" />} />
                    <Route path="ListPenaltyTypes/:id" element={<RulePackRecord entity="ListPenaltyType" />} />
                    <Route path="ListPeriods" element={<RulePackList entity="ListPeriod" />} />
                    <Route path="ListPeriods/:id" element={<RulePackRecord entity="ListPeriod" />} />
                    <Route path="ListPlayerLevels" element={<RulePackList entity="ListPlayerLevel" />} />
                    <Route path="ListPlayerLevels/:id" element={<RulePackRecord entity="ListPlayerLevel" />} />
                    <Route path="ListPersonRoles" element={<RulePackList entity="ListPersonRole" />} />
                    <Route path="ListPersonRoles/:id" element={<RulePackRecord entity="ListPersonRole" />} />
                    <Route path="ListPositions" element={<RulePackList entity="ListPosition" />} />
                    <Route path="ListPositions/:id" element={<RulePackRecord entity="ListPosition" />} />
                    <Route path="ListResultPoints" element={<RulePackList entity="ListResultPoint" />} />
                    <Route path="ListResultPoints/:id" element={<RulePackRecord entity="ListResultPoint" />} />
                    <Route path="ListResultTypes" element={<RulePackList entity="ListResultType" />} />
                    <Route path="ListResultTypes/:id" element={<RulePackRecord entity="ListResultType" />} />
                    <Route path="ListShottypes" element={<RulePackList entity="ListShotType" />} />
                    <Route path="ListShottypes/:id" element={<RulePackRecord entity="ListShotType" />} />
                    <Route path="ListColors" element={<RulePackList entity="ListColor" />} />
                    <Route path="ListColors/:id" element={<RulePackRecord entity="ListColor" />} />
                    <Route path="" element={<OrgNavigate to={"/rulepacks/ListEventLocations"} replace />} />
                </Route>
                <Route path="/embed" element={<EmbedLayout />}>
                    <Route path="games" element={<EmbedGames />} />
                    <Route path="standings" element={<EmbedStandings />} />
                    <Route path="players_stats" element={<EmbedPlayersStats />} />
                    <Route path="goalies_stats" element={<EmbedGoaliesStats />} />
                    <Route path="game" element={<EmbedGame />} />
                    <Route path="new_player" element={<EmbedNewPlayer />} />
                    <Route path="disciplinaries" element={<EmbedDisciplinaries />} />
                    <Route path="disciplinary" element={<EmbedDisciplinary />} />
                    <Route path="season_terms" element={<EmbedSeasonTerms />} />
                </Route>

            {/* start of backward compatibility solution */}
                <Route path={`/`} element={<LoginWrapper></LoginWrapper>}>
                    <Route path="profile" />
                    <Route path="dashboard" />
                    <Route path="competitions" />
                    <Route path="competitions" />
                    <Route path="competitions/:id" />
                    <Route path="seasons/:id" />
                    <Route path="groups/:id" />
                    <Route path="phases/:id" />
                    <Route path="sponsors" />
                    <Route path="sponsors/:id" />
                    <Route path="venues" />
                    <Route path="venues/:id" />
                    <Route path="awards" />
                    <Route path="awards/:id" />
                    <Route path="teams" />
                    <Route path="teams/:id" />
                    <Route path="players" />
                    <Route path="players/:id" />
                    <Route path="persons" />
                    <Route path="persons/:id" />
                    <Route path="games" />
                    <Route path="games/:id" />
                    <Route path="statistics" />
                    <Route path="schedulerconfigs" />
                    <Route path="schedulerconfigs/:id" />
                    <Route path="infographics" />
                    <Route path="disciplinaries" />
                    <Route path="disciplinaries/:id" />
                    <Route path="seasonregistrations" />
                    <Route path="seasonregistrations/:id" />
                    <Route path="gameplay/:id" />
                    <Route path="payment-status" />
                    <Route path="schedulerruns/:id" />
                    <Route path="schedulersimulations/:id" />
                    <Route path="dashboard_team_manager" />
                    <Route path="games_team_manager" />
                    <Route path="games_team_manager/:id" />
                    <Route path="teams_team_manager" />
                    <Route path="teams_team_manager/:id" />
                    <Route path="players_team_manager" />
                    <Route path="players_team_manager/:id" />
                    <Route path="persons_team_manager" />
                    <Route path="persons_team_manager/:id" />
                    <Route path="settings/organizations" />
                    <Route path="settings/organizations/:id" />
                    <Route path="settings/system_settings" />
                    <Route path="settings/system_settings/:id" />
                    <Route path="settings/users" />
                    <Route path="settings/users/:id" />
                    <Route path="settings" />
                    <Route path="rulepacks/ListEventLocations" />
                    <Route path="rulepacks/ListEventLocations/:id" />
                    <Route path="rulepacks/ListGoalLocations" />
                    <Route path="rulepacks/ListGoalLocations/:id" />
                    <Route path="rulepacks/ListGoalSubtypes" />
                    <Route path="rulepacks/ListGoalSubtypes/:id" />
                    <Route path="rulepacks/ListGoalTypes" />
                    <Route path="rulepacks/ListGoalTypes/:id" />
                    <Route path="rulepacks/ListInjuryTypes" />
                    <Route path="rulepacks/ListInjuryTypes/:id" />
                    <Route path="rulepacks/ListPenaltySubtypes" />
                    <Route path="rulepacks/ListPenaltySubtypes/:id" />
                    <Route path="rulepacks/ListPenaltyTypes" />
                    <Route path="rulepacks/ListPenaltyTypes/:id" />
                    <Route path="rulepacks/ListPeriods" />
                    <Route path="rulepacks/ListPeriods/:id" />
                    <Route path="rulepacks/ListPlayerLevels" />
                    <Route path="rulepacks/ListPlayerLevels/:id" />
                    <Route path="rulepacks/ListPersonRoles" />
                    <Route path="rulepacks/ListPersonRoles/:id" />
                    <Route path="rulepacks/ListPositions" />
                    <Route path="rulepacks/ListPositions/:id" />
                    <Route path="rulepacks/ListResultPoints" />
                    <Route path="rulepacks/ListResultPoints/:id" />
                    <Route path="rulepacks/ListResultTypes" />
                    <Route path="rulepacks/ListResultTypes/:id" />
                    <Route path="rulepacks/ListShottypes" />
                    <Route path="rulepacks/ListShottypes/:id" />
                    <Route path="rulepacks/ListColors" />
                    <Route path="rulepacks/ListColors/:id" />
                    <Route path="rulepacks/" />
                </Route>
            {/* end of compatibility solution */}

                <Route path="*" element={<AppErrorLayout type="404" />} />
            </>
        )
    );

    return (
        <RouterProvider router={router} />
    );
};
