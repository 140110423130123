import useFetchEntity from 'hooks/useFetchEntity';
import { useEffect, useMemo, useRef } from 'react';

import "@fontsource/audiowide";
import "@fontsource/bebas-neue";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import DebugPrinter from 'components/Debug/DebugPrinter';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import useFetchDataWithOrg from 'hooks/useFetchDataWithOrg';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import usePersistentStore from 'store/persistentStore';
import BackgroundImgSelector from './Filters/BackgroundImgSelector';
import MarginEditor from './Filters/MarginEditor';
import PhaseSelector from './Filters/PhaseSelector';
import XyEditor from './Filters/XyEditor';
import ZoomEditor from './Filters/ZoomEditor';
import { dateFormatIG, enableZoomByMouseWheel, keyForZoomByMouseWheel, stepForMoveByMouse, stepForZoomByMouseWheel } from './IGconfig';
import CompetitionLogo from './Parts/CompetitionLogo';
import IGHeader from './Parts/IGHeader';
import IGImg from './Parts/IGImg';
import TableStandings from './Parts/TablePM';

const IG5_StandingsByPoints = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const bgPictureUrl = usePersistentStore((state) => state.get('ig-standings-bg-url'));
  const setBgPictureUrl = usePersistentStore((state) => state.set('ig-standings-bg-url'));
  const size = usePersistentStore((state) => state.get('ig-standings-size'));
  const setSize = usePersistentStore((state) => state.set('ig-standings-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 625, height: 1111, preset: 'PORTRAIT' };

  const bgImgPositionX = usePersistentStore((state) => state.get('ig-standings-bgimgposition-x')) ?? 50;
  const setBgImgPositionX = usePersistentStore((state) => state.set('ig-standings-bgimgposition-x'));
  const bgImgPositionY = usePersistentStore((state) => state.get('ig-standings-bgimgposition-y')) ?? 50;
  const setBgImgPositionY = usePersistentStore((state) => state.set('ig-standings-bgimgposition-y'));
  const bgImgZoom = usePersistentStore((state) => state.get('ig-standings-zoom')) ?? 100;
  const setBgImgZoom = usePersistentStore((state) => state.set('ig-standings-zoom'));
  const bgImgRef = useRef(null);

  const igname = 'standings';
  const marginDefaults = { top: 32, left: 32, bottom: 32, right: 32};
  const bgImgMargin = usePersistentStore((state) => state.get(`ig-${igname}-margin`)) ?? marginDefaults;
  const setBgImgMargin = usePersistentStore((state) => state.set(`ig-${igname}-margin`));

  useEffect(() => {
    if (!enableZoomByMouseWheel) return;

    const handleWheel = (e) => {
      if (!e[keyForZoomByMouseWheel]) return;
      e.preventDefault();
      const newZoom = (bgImgZoom == null || isNaN(bgImgZoom) ? 0 : bgImgZoom) + (e.deltaY > 0 ? -stepForZoomByMouseWheel : stepForZoomByMouseWheel); // Adjust zoom level
      setBgImgZoom(Math.max(50, Math.min(200, newZoom))); // Clamp zoom between 50% and 200%
    };

    const bgImgElement = bgImgRef.current;
    if (bgImgElement) {
      bgImgElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (bgImgElement) {
        bgImgElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [bgImgZoom, setBgImgZoom]);

  const handleBgImgMouseMove = (e) => {
    if (e.buttons !== 1) return; // Only move on left mouse click

    setBgImgPositionX(Math.min(100, Math.max(0, bgImgPositionX + e.movementX / stepForMoveByMouse)));
    setBgImgPositionY(Math.min(100, Math.max(0, bgImgPositionY + e.movementY / stepForMoveByMouse)));
  };

  const { phaseIdsAll } = usePhaseIdTranslator(membership);
  const groupId = membership?.groupId;
  const seasonId = membership?.seasonId;

  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');
  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');

  const teamStandings = useFetchDataWithOrg(phaseIdsAll
    ? `/admin/tableStandings?phaseIds=${phaseIdsAll?.join(',')}`
    : null);

  const metadata = useMemo(() => {
    return {
      GroupName: groupDetail?.name,
      GroupColor: groupDetail?.color,
      SeasonName: seasonDetail?.name,
      CompetitionName: seasonDetail?.Competition?.name,
      CompetitionLogo:
        seasonDetail?.Competition?.logoUrl?.replace(
          "[size]",
          "cropped_md",
        ),
      Date: dayjs().format(dateFormatIG),
    };
  }, [groupDetail, seasonDetail]);

  const data = useMemo(() => {
    const data = teamStandings?.data?.map((team) => ({
      Name: team["Team Name"],
      Logo: team["TeamLogo"]?.replace("[size]", "cropped_md"),
      Z: team["Total Games"],
      V: team["Wins"],
      P: team["Losses"],
      R: team["Draws"],
      Score: team["Score"],
      B: team["Total Points"],
      TM: team["Total Penalty Minutes"],
    })) ?? [];

    const sorted = data.sort((a, b) => {
      return b.B - a.B;
    });

    return sorted;
  }, [teamStandings]);


  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Background picture:</span>
        <div>
          <BackgroundImgSelector bgPictureUrl={bgPictureUrl} setBgPictureUrl={setBgPictureUrl} igId={5} />
          <div className='flex gap-6 items-center mt-2'>
            <XyEditor
              xValue={bgImgPositionX}
              yValue={bgImgPositionY}
              setXValue={setBgImgPositionX}
              setYValue={setBgImgPositionY}
              STEP={10}
              isPercentage={true}
            />
            <ZoomEditor zoomLevel={bgImgZoom} setZoomLevel={setBgImgZoom} />
          </div>
        </div>

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <span>Margin:</span>
        <div className="flex gap-2 items-center">
          <MarginEditor
            margin={bgImgMargin}
            setMargin={setBgImgMargin}
            defaults={marginDefaults}
          />  
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[metadata, teamStandings]}></DebugPrinter>)}

      {phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto w-[625px] bg-hockey-page-bg font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
          }}
        >
          <div className="flex h-full flex-col items-center justify-between">

            <div style={{
                          paddingTop: `${bgImgMargin.top}px`,
                          paddingLeft: `${bgImgMargin.left}px`,
                          paddingRight: `${bgImgMargin.right}px`,
                          paddingBottom: `${bgImgMargin.bottom}px`,
                        }}
            >
              <IGHeader
                title={`Standings - ${metadata.CompetitionName} ${metadata.SeasonName}`}
                league={metadata.GroupName}
                leagueStyle={{ color: metadata.GroupColor ?? '#777777' }}
                small
                date={metadata.Date}
              />
              <TableStandings data={data} />
            </div>

            <div className='w-full'>
              <div className="relative h-[80px] z-10">
                <IGImg
                  src="/images/most-points.png"
                  className="absolute top-0 -mt-14 h-[250px]"
                  alt=""
                />
              </div>
              <div
                ref={bgImgRef}
                onMouseMove={handleBgImgMouseMove}
                className='w-full z-20 relative aspect-[24/10]'
                style={{
                  backgroundImage: `url('${process.env.REACT_APP_IMAGE_PROXY_URL}?url=${bgPictureUrl}')`,
                  backgroundSize: `${bgImgZoom ?? 100}%`,
                  backgroundPosition: `${bgImgPositionX ?? 50}% ${bgImgPositionY ?? 50}%`, // Dynamic positioning
                  backgroundRepeat: 'no-repeat',
                  cursor: 'grab', // Visual cue for draggable behavior
                  clipPath: "polygon(450px 0, 100% 0, 100% 100%, 0 100%, 0 70px)",
                }}
                onMouseDown={(e) => e.target.style.cursor = 'grabbing'} // Change cursor while dragging
                onMouseUp={(e) => e.target.style.cursor = 'grab'} // Reset cursor
              ></div>
              <CompetitionLogo
                className="mx-auto scale-75 p-4"
                src={metadata.CompetitionLogo}
                title={metadata.CompetitionName}
                season={metadata.SeasonName}
              />
            </div>
          </div>
        </article>
      }
    </>
  );
};

export default IG5_StandingsByPoints;
