
function DotRotatedText({ firstText, secondText }) {
    return (
        <>
            <div className="font-dot uppercase rotate-[-9.2deg] text-igdot relative left-10">{firstText}</div>
            <div className="font-dot uppercase rotate-[-9.2deg] text-igdot relative left-[3.25rem]">{secondText}</div>
        </>
    )
}

export default DotRotatedText