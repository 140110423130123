import { Button, InputNumber } from 'antd';

function ZoomEditor({ zoomLevel, setZoomLevel }) {

  const increaseZoom = () => {
    console.log('increaseZoom', zoomLevel);
    setZoomLevel(zoomLevel+ 10);
  };

  const decreaseZoom = () => {
    setZoomLevel(zoomLevel - 10);
  };

  const resetZoom = () => {
    setZoomLevel(100); 
  };

  return (
    <div className='flex gap-2 items-center'>
      <Button onClick={decreaseZoom}>-</Button>
      <InputNumber
        min={0}
        value={zoomLevel}
        onChange={value => setZoomLevel(value)}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        suffix="Z"
      />
      <Button onClick={increaseZoom}>+</Button>
      <Button onClick={resetZoom}>Reset</Button>
    </div>
  );
}

export default ZoomEditor;