import EntityLogo from 'components/EntityLogo';
import HmsEntityTableController from 'components/HmsEntityTableController';
import HmsStatusTag from 'components/HmsStatusTag';
import useEntities from 'hooks/useEntities';
import moment from 'moment';
import { formatDate, formatTime } from 'utils/generateEntityColumns';

const GamesTeamManager = () => {
  const entities = useEntities();

  const filterGroups = [
    [
      { label: 'Next 7 days', preferredSort: { column: 'StartDateTime-nowrap', direction: 'ascend' }, filters: { startDate: ['>' + moment().subtract(1, 'days').format('YYYY-MM-DD'), '<' + moment().add(8, 'days').format('YYYY-MM-DD')] } },
      { label: 'Future',      preferredSort: { column: 'StartDateTime-nowrap', direction: 'ascend' }, filters: { startDate: ['>' + moment().subtract(1, 'days').format('YYYY-MM-DD')] } },
    ],
  ];

  return (
    <HmsEntityTableController
      entity={entities.Game}
      filterGroups={filterGroups}
      exportEnabled={false}
      showColumns={[
        { column: 'StartDateTime-nowrap', width: 200, defaultSortOrder: 'descend', },
        { column: 'Venue', width: 200, },
        { column: 'TeamsVs-nick', width: 350, },
        { column: 'status', width: 125, },
        { column: 'referee', width: 200, },
        { column: 'timekeeper', width: 200, },
      ]}
      renderMobileRow={(record) => (
        <div>
          <div className="border-b-[1px] border-gray-100 px-4 py-4 flex rounded-t-md" style={{ background: 'linear-gradient(90deg, #f2f2f2 0%, #fafafa 2%, #fafafa 98%, #f2f2f2 100%)' }}>
            <div className="flex flex-col gap-[1px]">
              <div className="font-bold whitespace-nowrap text-[0.9rem]">{
                formatDate(record.startDate, { withWeekDay: true }) +
                (record.startDate && record.startDate != '' && record.startTime && record.startTime != '' ? ', ' : '') +
                formatTime(record.startTime)
              }</div>
              <div className="text-gray-400 whitespace-nowrap text-[0.8rem]">{
                record.Venue?.name
              }</div>
            </div>
            <div className="ml-auto my-auto">
              <HmsStatusTag className="m-0" status={record.status} entity="Game" />
            </div>
          </div>
          <div className="px-6 py-4 flex items-center text-[1.05rem] rounded-b-md" style={{ background: 'linear-gradient(90deg, #f8f8f8 0%, #fff 2%, #fff 98%, #f8f8f8 100%)' }}>
            <div className="flex-1 flex-shrink-0 text-right pr-3">{record.HomeTeam?.nick}</div>
            <div className="w-[30px] text-center"><EntityLogo entity={record.HomeTeam} size={32} /></div>
            <div className="w-[35px] text-gray-500 text-center">vs</div>
            <div className="w-[30px] text-center"><EntityLogo entity={record.AwayTeam} size={32} /></div>
            <div className="flex-1 flex-shrink-0 text-left pl-3">{record.AwayTeam?.nick}</div>
          </div>
        </div>
      )}
      onRowClickLink={(record) => `/games_team_manager/${record.gameId}`}
      canCreate={false}
    />
  )
}

export default GamesTeamManager