import { DownOutlined, LeftOutlined, RightOutlined, UpOutlined } from '@ant-design/icons';
import { Button, InputNumber } from 'antd';
import React from 'react';


function XyEditor({ xValue, yValue, setXValue, setYValue, isInvertedPosLogic = false, 
    suffixX = 'X', suffixY = 'Y', STEP = 1, precision = 0, isPercentage = false }) {

    const X_DIR = 'x';
    const Y_DIR = 'y';
    const changePositionBy = (direction, isNegative) => {
        let increment = isNegative ? -STEP : STEP;
        increment = isInvertedPosLogic ? -increment : increment;
        const origValue = direction === X_DIR ? xValue : yValue;
        const setFunction = direction === X_DIR ? setXValue : setYValue;
        if (isNaN(origValue)) setFunction(increment);
        else setFunction(origValue + increment);
    }

    xValue = isNaN(xValue) ? 0 : xValue;
    yValue = isNaN(yValue) ? 0 : yValue;

    const reset = () => {
        setXValue(0);
        setYValue(0);
    }

    const formatValue = (value) => {
        return isPercentage ? `${parseFloat(value).toFixed(precision)}%` : parseFloat(value).toFixed(precision);
    };

    const parseValue = (value) => {
        return isPercentage ? parseFloat(value.replace('%', '')).toFixed(precision) : parseFloat(value).toFixed(precision);
    };

    return (
        <div className='flex gap-2 items-center'>
            <InputNumber 
                value={xValue}
                suffix={suffixX}
                formatter={formatValue}
                parser={parseValue}
                precision={precision}
                onChange={(value) => setXValue(value)}
            />

            <InputNumber 
                suffix={suffixY}
                value={yValue}
                formatter={formatValue}
                parser={parseValue}
                precision={precision}
                onChange={(value) => setYValue(value)}
            />
            <React.Fragment>
                <Button onClick={() => changePositionBy(X_DIR, true)} ><LeftOutlined /></Button>
                <Button onClick={() => changePositionBy(X_DIR, false)} ><RightOutlined /></Button>
                <Button onClick={() => changePositionBy(Y_DIR, true)}><DownOutlined /></Button>
                <Button onClick={() => changePositionBy(Y_DIR, false)}><UpOutlined /></Button>
            </React.Fragment>
            <Button onClick={reset}>Reset</Button>
        </div>
    )
}

export default XyEditor