import { InputNumber } from 'antd';
import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import { useMemo } from 'react';
import { formatTimeStringToHHMM } from 'utils/IGdateTimeFunctions';

import "@fontsource/audiowide";
import "@fontsource/bebas-neue";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import DebugPrinter from 'components/Debug/DebugPrinter';
import DateSelector from 'components/Infographics/Filters/DateSelector';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import usePersistentStore from 'store/persistentStore';
import { sortDateAndTime } from 'utils/sorters';
import MarginEditor from './Filters/MarginEditor';
import PhaseSelector from './Filters/PhaseSelector';
import VenueSelector from './Filters/VenueSelector';
import { dateFormatIG } from './IGconfig';
import GameMatchRecap from './Parts/GameMatchRecap';
dayjs.extend(customParseFormat);

function IG3_WeekRecap({ showData, exportRef }) {
    const membership = usePersistentStore((state) => state.get('ig-membership'));
    const setMembership = usePersistentStore((state) => state.set('ig-membership'));
    const venue = usePersistentStore((state) => state.get('ig-venue'));
    const setVenue = usePersistentStore((state) => state.set('ig-venue'));
    const date = usePersistentStore((state) => state.get('ig-date')) ?? dayjs(new Date()).format(dateFormatIG);
    const setDate = usePersistentStore((state) => state.set('ig-date'));
    const numDays = usePersistentStore((state) => state.get('ig-weekrecap-numdays')) ?? 7;
    const setNumDays = usePersistentStore((state) => state.set('ig-weekrecap-numdays'));
    const size = usePersistentStore((state) => state.get('ig-weekrecap-size'));
    const setSize = usePersistentStore((state) => state.set('ig-weekrecap-size'));
    const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
    const DEFAULT_SIZE = { width: 820, height: 1400, preset: 'PORTRAIT' }; //, widthReadonly: true };

    const igname = 'weekrecap';
    const marginDefaults = { top: 16, left: 16, bottom: 16, right: 16};
    const bgImgMargin = usePersistentStore((state) => state.get(`ig-${igname}-margin`)) ?? marginDefaults;
    const setBgImgMargin = usePersistentStore((state) => state.set(`ig-${igname}-margin`));
  

    const { phaseIdsAll } = usePhaseIdTranslator(membership);
    const groupId = membership?.groupId;

    const startWeek = date;
    const endWeek = dayjs(date, dateFormatIG).add(numDays - 1, 'day').format(dateFormatIG);
    const startDateFilter = [`>=${startWeek}`, `<=${endWeek}`];

    const venueIdsAll = venue?.venueIds == null || venue?.venueIds.length === 0 ? undefined : venue?.venueIds;


    const { data: gamesWeekReporter } =
        useFetchEntities(
            phaseIdsAll != null && date != null && 'Game',
            {
                filters: { startDate: startDateFilter, phaseId: phaseIdsAll, venueId: venueIdsAll },
                relations: 'AwayTeamStars,HomeTeamStars,Venue(name),HomeTeam(nick,name,logoUrl,externalId),AwayTeam(nick,name,logoUrl,externalId),Phase(name),Phase>Group(name),Phase>Group>Season(name),Phase>Group>Season>Competition(name)',
                extraAttributes: 'HomeTeamGoals,AwayTeamGoals'
            }
        );

    const { data: groupDetails } = useFetchEntity('Group', groupId, 'Season>Competition');

    const formatStarPlayerName = (player) => {
        if (player == null || player.firstName == null || player.lastName == null) return null;
        return player.firstName + " " + player.lastName;
    }

    const data = useMemo(() => {
        let data = (gamesWeekReporter ?? [])
            .sort(
                (g1, g2) => sortDateAndTime(g1.startDate, g1.startTime, g2.startDate, g2.startTime)
            )
            .map((game) => ({
                HomeStar:
                    formatStarPlayerName(game.HomeTeamStars?.[0]?.Player),
                HomeTeamName: game.HomeTeam?.nick,
                HomeTeamLogo: game.HomeTeam?.logoUrl?.replace("[size]", "cropped_md"),
                AwayStar:
                    formatStarPlayerName(game.AwayTeamStars?.[0]?.Player),
                AwayTeamName: game.AwayTeam?.nick,
                AwayTeamLogo: game.AwayTeam?.logoUrl?.replace("[size]", "cropped_md"),
                Score: `${game.HomeTeamGoals}:${game.AwayTeamGoals}`,
                Time: formatTimeStringToHHMM(game.startTime),
                Date: game.startDate,
                VenueName: game.Venue?.name,
            }))

        return data;
    }, [gamesWeekReporter, groupDetails]);

    return (
        <>
            <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

                <span>Membership:</span>
                <PhaseSelector
                    value={membership}
                    onChange={setMembership}
                    mandatory={['competitionId', 'seasonId', 'groupId']}
                />

                <span>Time span:</span>
                <div className="flex gap-2 place-items-center">
                    <DateSelector
                        value={dayjs(date, dateFormatIG)}
                        onChange={(dateString) => { setDate(dateString) }}
                    />
                    <span className="ml-6">Length:</span>
                    <InputNumber
                        min={1}
                        defaultValue={numDays}
                        onChange={setNumDays}
                        addonAfter="days"
                        style={{ width: '120px' }}
                    />
                    <span className='text-gray-400 text-xs'> ({startWeek} – {endWeek})</span>
                </div>

                <span>Choose venue:</span>
                <div>
                    <VenueSelector
                        value={venue}
                        onChange={(venue) => { setVenue(venue) }}
                    />
                </div>

                <span>Dimensions:</span>
                <div className="flex gap-2 items-center">
                    <SizeSelector
                        value={size}
                        onChange={setSize}
                        presets={SIZE_PRESETS}
                        defaultValue={DEFAULT_SIZE}
                    />
                </div>


                <span>Margin:</span>
                <div className="flex gap-2 items-center">
                    <MarginEditor
                        margin={bgImgMargin}
                        setMargin={setBgImgMargin}
                        defaults={marginDefaults}
                    />
                </div>

                <div />
            </div>

            {showData && (<DebugPrinter data={[gamesWeekReporter, data, date]}></DebugPrinter>)}

            {date && phaseIdsAll && groupDetails &&
                <article
                    ref={exportRef}
                    className="mx-auto h-full w-fit bg-hockey-page-bg p-4 font-header text-white"
                    style={{
                        width: `${size?.width}px`,
                        height: `${size?.height}px`,
                        paddingTop: `${bgImgMargin.top}px`,
                        paddingLeft: `${bgImgMargin.left}px`,
                        paddingRight: `${bgImgMargin.right}px`,
                        paddingBottom: `${bgImgMargin.bottom}px`,
                    }}
                >
                    <h1 className="mb-16 w-full text-center text-5xl text-hockey-page-bg" style={{ background: groupDetails?.color ?? '#777777' }}>
                        {groupDetails?.name ?? '-'}
                    </h1>
                    <div className="min-w-[784px]">  { /* min-w necessary to keep table from shrinking when there is no data */}
                        {data.length > 0
                            ?
                            <main className="mt-8 border-t border-dotted border-white min-w-[784px]">
                                {data.map((game, index) => (
                                    <GameMatchRecap key={index} game={game} />
                                ))}
                            </main>
                            :
                            <div className="h-full min-h-[200px] w-full flex flex-col gap-2 items-center justify-center text-2xl uppercase text-center">
                                <span>No games between:</span>
                                <span>{startWeek} – {endWeek}</span>
                            </div>
                        }
                    </div>
                </article>
            }

        </>
    )
}

export default IG3_WeekRecap